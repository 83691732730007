import NextLink from 'next/link'
import styled from 'styled-components'
import { theme, Link, Button } from '@/client/components'
const { breakpoint, borders, color, space, font } = theme

export const Header = styled.header`
  position: relative;
  z-index: 10;
  background-color: ${color.grayscale.white};
`

export const SkipLink = styled.a`
  position: absolute;
  top: -10rem;
  left: 0;
  transition: top 0.3s ease;
  padding: ${space.xs} ${space.md};
  background-color: ${color.primary.pure};
  color: ${color.grayscale.white};
  font-size: ${font.size.root};
  font-weight: ${font.weight.bold};

  &:focus {
    top: 0;
  }
`

export const TopHeader = styled.div`
  display: none;

  @media (min-width: ${breakpoint.xl}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${space.xs} 0;
  }
`

const TopHeaderListStyle = `
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TopHeaderInfos = styled.div`
  ${TopHeaderListStyle}
`

export const TopHeaderList = styled.nav`
  ${TopHeaderListStyle}
`

export const Flag = styled.button`
  all: unset;
  display: block;
  cursor: pointer;
  width: 21px;
  height: 15px;

  &:not(:first-of-type) {
    margin-left: ${space.sm};
  }
`

export const TopHeaderLinks = styled(Link)`
  margin-left: ${space.md};

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: ${space.md};
    margin-right: ${space.md};
    background-color: ${color.grayscale.gray};
  }
`

export const MainHeader = styled.div`
  padding: ${space.md} 0;
`

export const Logo = styled.div`
  max-width: 12.5rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const MainHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const LoginAnchor = styled(NextLink)`
  display: flex;
  align-items: flex-start;
  gap: ${space.xs2};
  margin-right: ${space.sm};
  color: ${color.grayscale.black};
  transition: color 0.4s ease;

  &:hover,
  &:active {
    color: ${color.primary.pure};
    text-decoration: none;
  }

  @media (min-width: ${breakpoint.lg}) {
    margin-right: ${space.md};
  }
`

export const Greet = styled.span`
  display: block;
  font-size: ${font.size.xxsmall};
  line-height: 1.1;
`

export const Cart = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: ${space.lg};
  padding: 0;
  border: 0;
  color: ${color.grayscale.black};

  &:hover,
  &:active {
    color: ${color.primary.pure};
    text-decoration: none;
  }

  @media (min-width: ${breakpoint.lg}) {
    margin-right: ${space.xl};
  }
`

export const CartNumber = styled.span`
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${color.primary.dark};
  border: ${borders.sizes.sm} solid ${color.grayscale.white};
  color: ${color.grayscale.white};
  font-size: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 1.125rem;
  font-weight: ${font.weight.bold}; ;
`

export const HeaderButton = styled(Button)`
  display: none;

  @media (min-width: ${breakpoint.lg}) {
    display: flex;
  }
`

export const MenuButton = styled.button`
  display: block;
  border: 0;
  background-color: transparent;

  @media (min-width: ${breakpoint.lg}) {
    display: none;
  }
`

export const BottomHeader = styled.div<{ page: boolean }>`
  display: none;
  width: 100%;
  background-color: ${(props) => (props.page ? color.grayscale.white : color.grayscale.headerGray)};

  @media (min-width: ${breakpoint.lg2}) {
    display: block;
  }
`

export const BottomHeaderList = styled.ul`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  height: 3.5rem;
`

type MenuListTypes = {
  size: number
}

export const MenuList = styled.nav<MenuListTypes>`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  flex-flow: column wrap;
  justify-content: space-around;
  padding: ${space.lg};
  gap: ${space.xs};
  width: ${(p) => (p.size === 0 ? 18.75 : p.size)}rem;
  max-height: 30rem;
  padding: ${space.lg};
  background-color: ${color.grayscale.lighter};
  box-shadow: 0px 9px 10px 0px rgba(16, 33, 51, 0.1);
  transition: display 0.3s ease;

  .subtitle {
    font-weight: ${font.weight.bold};

    &:not(:first-of-type) {
      margin-top: ${space.md};
    }
  }
`

export const BottomHeaderListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${space.xs};
  color: ${color.primary.darker};
  font-size: ${font.size.small};
  cursor: pointer;

  & > .icon {
    display: none;
    margin-left: ${space.xs};

    @media (min-width: ${breakpoint.xl}) {
      display: block;
    }
  }

  &:nth-of-type(2),
  &:nth-of-type(3),
  &:nth-of-type(4),
  &:nth-of-type(5),
  &:nth-of-type(6) {
    display: none;
  }

  @media (min-width: ${breakpoint.md}) {
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      display: flex;
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    &:nth-of-type(5),
    &:nth-of-type(6) {
      display: flex;

      ${MenuList} {
        right: 0;
        left: unset;
      }
    }
  }

  @media (min-width: ${breakpoint.hd}) {
    font-size: ${font.size.root};
  }

  &:hover ${MenuList} {
    display: flex;
  }

  &:last-of-type ${MenuList} {
    right: 0;
    left: unset;
  }
`

export const CustomerService = styled.span`
  color: ${color.primary.pure};

  span {
    color: ${color.primary.darker};
    font-weight: ${font.weight.bold};
  }
`
