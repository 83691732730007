import styled from 'styled-components'
import { theme, Link } from '@/client/components'
const { breakpoint, borders, color, space, font } = theme

export const Infos = styled.div`
  padding: ${space.xl3} ${space.md};
  background-color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.xl4} 0;
  }
`

export const InfoCards = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${space.lg};

  @media (min-width: ${breakpoint.xl}) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 22rem;
  min-width: 310px;
  border-radius: ${borders.radius.lg};
  background-color: ${color.grayscale.softWhite};
  overflow: hidden;

  img {
    width: 100%;
  }

  @media (min-width: ${breakpoint.xl}) {
    min-width: auto;
  }
`

export const InfoCardTop = styled.div`
  padding: ${space.md};
`

export const InfoCardBottom = styled.div`
  padding: ${space.md};
  button {
    width: 100%;
  }
`
export const CustomerServiceButton = styled.div`
  width: 100%;
  background-color: ${color.warning.pure};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 3rem;
  line-height: 1;
  gap: ${space.xs2};

  .icon {
    position: relative;
    top: 0.2rem;
  }

  @media (min-width: ${breakpoint.xl2}) {
    gap: ${space.md};
  }
`

export const TopFooter = styled.div`
  padding: ${space.xl3} 0 ${space.lg};
  border-bottom: ${borders.sizes.sm} solid ${color.grayscale.babyBlue};

  background-color: ${color.grayscale.babyBlue};
  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.xl4} 0;
  }
`

export const TopFooterItem = styled.div`
  margin-bottom: ${space.xl};

  @media (min-width: ${breakpoint.lg}) {
    margin-bottom: 0;
  }
`

export const TopFooterCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${space.xl4};
  height: ${space.xl4};
  margin: 0 auto ${space.lg};
  border-radius: ${borders.radius.circle};
  background-color: ${color.primary.darker};
  color: ${color.grayscale.white};
`

export const FooterLinks = styled.div`
  padding: ${space.xl} 0;
  border-bottom: ${borders.sizes.sm} solid ${color.grayscale.lighter};
`

export const FooterInfos = styled.div`
  padding: ${space.xl3} 0 ${space.md};
  background-color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.xl4} 0;
    border-bottom: ${borders.sizes.sm} solid ${color.grayscale.light};
  }
`

export const FooterInfosItem = styled.div`
  margin-bottom: ${space.lg};

  @media (min-width: ${breakpoint.lg}) {
    margin-bottom: 0;
  }
`

export const PaymentList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
  margin: 0 0 ${space.md};
`

export const PaymentListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: ${space.xl};
  margin-bottom: ${space.sm};
  padding: ${space.xs2};
  border-radius: ${borders.radius.md};
  border: ${borders.sizes.sm} solid ${color.grayscale.light};
  font-size: 0.56rem;
  font-weight: ${font.weight.bold};

  &:not(:last-child) {
    margin-right: ${space.xs};
  }
`

export const HorizontalList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

export const WrapImage = styled.div<{ width: string }>`
  width: ${(p) => p.width}px;
  min-width: ${(p) => p.width}px;
  height: auto;

  &:not(:last-of-type) {
    margin-right: ${space.sm};
    margin-bottom: ${space.sm};
  }
`

export const BottomFooter = styled.div`
  padding: ${space.xl2} 0 ${space.lg};
  background-color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.xl4} 0;
  }
`

export const Logo = styled.div`
  max-width: 10.5rem;
  margin-bottom: ${space.lg};

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const NavSocialLink = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.lg};

  @media (min-width: ${breakpoint.lg}) {
    justify-content: flex-start;
  }
`

const getTypeColor = (type: string) => {
  if (type === 'facebook') return '#0062E0'
  if (type === 'instagram') return '#f50057'
  if (type === 'youtube') return '#FF0000'
  if (type === 'linkedin') return '#0077b5'
  return '#0062E0'
}

export const SocialLink = styled.a<{ type: string }>`
  display: block;
  color: ${color.grayscale.gray};
  transition: color 0.3s ease;

  &:not(:last-of-type) {
    margin-right: ${space.xl};

    @media (min-width: ${breakpoint.lg}) {
      margin-right: ${space.sm};
    }
  }

  &:hover {
    color: ${(p) => getTypeColor(p.type)};
    text-decoration: none;
  }
`

export const CollapseWrapper = styled.div`
  width: 100%;
  padding: ${space.xl} 0;
  margin-bottom: ${space.xl2};
  background-color: ${color.grayscale.lighter};
`

export const LinkList = styled.nav`
  display: flex;
  flex-direction: column;
  margin-bottom: ${space.xl};
`

export const LinkFooter = styled(Link)`
  line-height: 2.5rem;

  @media (min-width: ${breakpoint.md}) {
    font-size: ${font.size.small};
    line-height: 2rem;
  }
`
