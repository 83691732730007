import { FC } from 'react'
import { Row, Col, Text, Button, HorizontalScroll, Icon, Image } from '@/client/components'
import { useRouterPush, useWindowSize } from '@/client/hooks'
import { IInfos, IInfoCards } from '@/client/types'
import * as S from './styles'

const InfoCards: FC<IInfoCards> = ({ material, customer_service, our_customers }) => {
  const { routerPush } = useRouterPush()

  return (
    <>
      <S.InfoCard>
        <Image src={material.image.url} alt={material.image.alt} width='419' height='120' />
        <S.InfoCardTop>
          <Text size='xlg' family='heading' weight='bold' margin='0 0 0.5rem'>
            {material.title}
          </Text>
          <Text size='sm' lineHeight='1.3'>
            {material.description}
          </Text>
        </S.InfoCardTop>

        <S.InfoCardBottom>
          <Button kind='warning' onClick={() => routerPush(material.button_link)}>
            {material.button_label}
          </Button>
        </S.InfoCardBottom>
      </S.InfoCard>

      <S.InfoCard>
        <Image src={customer_service.image.url} alt={customer_service.image.alt} width='419' height='120' />
        <S.InfoCardTop>
          <Text size='xlg' family='heading' weight='bold' margin='0 0 0.5rem'>
            {customer_service.title}
          </Text>
          <Text size='sm' lineHeight='1.3'>
            {customer_service.time}
          </Text>
          <Text size='sm' lineHeight='1.3' margin='0'>
            {customer_service.description}
          </Text>
        </S.InfoCardTop>

        <S.InfoCardBottom>
          <S.CustomerServiceButton>
            <Text weight='bold' margin='0 0 .5rem'>
              <Icon name='email' size='s4' className='icon' />
              {customer_service.email}
            </Text>

            <Text weight='bold' margin='0 0 .5rem'>
              <Icon name='phone' size='s4' className='icon' />
              {customer_service.phone_number}
            </Text>
          </S.CustomerServiceButton>
        </S.InfoCardBottom>
      </S.InfoCard>

      <S.InfoCard>
        <Image src={our_customers.image.url} alt={our_customers.image.alt} width='419' height='120' />
        <S.InfoCardTop>
          <Text size='xlg' family='heading' weight='bold' margin='0 0 0.5rem'>
            {our_customers.title}
          </Text>
          <Text size='sm' lineHeight='1.3'>
            {our_customers.description}
          </Text>
        </S.InfoCardTop>
        <S.InfoCardBottom>
          <Button kind='warning' onClick={() => routerPush(our_customers.button_link)}>
            {our_customers.button_label}
          </Button>
        </S.InfoCardBottom>
      </S.InfoCard>
    </>
  )
}

export const Infos: FC<IInfos> = ({ infos: { material, customer_service, our_customers } }) => {
  const windowSize = useWindowSize()
  const isXlBreakpoint = windowSize?.width > 992

  return (
    <S.Infos>
      <Row>
        <Col xs={12}>
          {!isXlBreakpoint && (
            <HorizontalScroll>
              <S.InfoCards>
                <InfoCards material={material} customer_service={customer_service} our_customers={our_customers} />
              </S.InfoCards>
            </HorizontalScroll>
          )}
          {isXlBreakpoint && (
            <S.InfoCards>
              <InfoCards material={material} customer_service={customer_service} our_customers={our_customers} />
            </S.InfoCards>
          )}
        </Col>
      </Row>
    </S.Infos>
  )
}
