import { FC, ReactNode, useEffect, useState, useCallback } from 'react'
import dynamic from 'next/dynamic'
import { Icon } from '@/client/components'
import { useScrollPosition } from '@/client/hooks'
import { IHeader, IFooter } from '@/client/types'
import { SEOProps } from '@/client/types'
import * as S from './styles'
import SideBarMenu from '../SideBarMenu'
import Header from '../Header'
import Head from './Head'
import Footer from '../Footer'

type PageProps = {
  seo?: SEOProps
  children?: ReactNode
} & IHeader &
  IFooter

const Page: FC<PageProps> = ({ seo, children, header, footer }) => {
  const [showButton, setShowButton] = useState<boolean>(false)
  const [isSidebarOpen, setIsSideBarOpen] = useState<boolean>(false)

  const offset = useScrollPosition()

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    })
  }, [])

  useEffect(() => {
    if (offset > 1000) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [offset])

  return (
    <>
      {seo && <Head seo={seo} />}
      <Header header={header} setIsSideBarOpen={setIsSideBarOpen} isSidebarOpen={isSidebarOpen} />
      <main id='main'>{children}</main>
      <Footer footer={footer} />
      {showButton && (
        <S.BackToTop onClick={scrollToTop}>
          <Icon name='arrow-top' size='s5' />
        </S.BackToTop>
      )}
      <SideBarMenu
        options={header.menu_navigation}
        commercialLinks={footer.bottom_footer.footer_links}
        socialLinks={footer.bottom_footer.left_side}
        setIsSideBarOpen={setIsSideBarOpen}
        isSidebarOpen={isSidebarOpen}
      />
    </>
  )
}

export default Page
